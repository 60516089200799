/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */

import { LATEST_CMP_VERSION } from "../../../utils/constants";

/* eslint-disable max-len */
export const getUspGeoGroup = uspJurisdiction => (uspJurisdiction && uspJurisdiction.includes('CA') ? 'usca' : 'us');

/* it returns a string with amp tag properties based on the configuration */
export const buildProperties = (config, isPersistentLink) => {
  const { coreConfig: { privacyMode = [], uspJurisdiction, displayUi, cmpVersion = LATEST_CMP_VERSION } } = config;
  const checkConsentUrl = process.env.REACT_APP_CHOICE_2_RENDER_TESTING_FEATURES ?
    'https://test-api.cmp.inmobi.com/amp/check-consent'
    : 'https://api.cmp.inmobi.com/amp/check-consent';
  
    const isCmpVersionLatest = cmpVersion == 'latest';

    let promptUISrcUrl;
    
    if (process.env.REACT_APP_CHOICE_2_RENDER_TESTING_FEATURES) {
      if (isCmpVersionLatest) {
        promptUISrcUrl = 'https://test.cmp.inmobi.com/tcfv2/amp.html';
      } else {
        promptUISrcUrl = `https://test.cmp.inmobi.com/tcfv2/${cmpVersion}/amp.html`;
      }
    } else {
      if (isCmpVersionLatest) {
        promptUISrcUrl = 'https://cmp.inmobi.com/tcfv2/amp.html';
      } else {
        promptUISrcUrl = `https://cmp.inmobi.com/tcfv2/${cmpVersion}/amp.html`;
      }
    }

  const properties = {
    consentInstanceId: 'inmobi',
    checkConsentHref: checkConsentUrl,
    consentRequired: false
  };
  const hasUsp = privacyMode && privacyMode.includes('USP');
  const hasGdpr = privacyMode && privacyMode.includes('GDPR');
  const uspGeoGroup = getUspGeoGroup(uspJurisdiction);
  const geoOverrides = {};

  if (hasGdpr) {
    if (displayUi === 'always') {
      properties.consentRequired = 'remote';
      properties.promptUISrc = promptUISrcUrl;
      if (isPersistentLink) {
        properties.postPromptUI = 'postPromptUI';
      }
    } else {
      geoOverrides.eea = {
        consentRequired: 'remote',
        promptUISrc: promptUISrcUrl
      };
      if (isPersistentLink) {
        geoOverrides.eea.postPromptUI = 'postPromptUI';
      }
    }
  }

  if (hasUsp) {
    geoOverrides[uspGeoGroup] = {
      consentRequired: 'remote',
      promptUISrc: `${promptUISrcUrl}?usp`
    };
    if (hasGdpr) {
      geoOverrides[uspGeoGroup] = { ...geoOverrides[uspGeoGroup], postPromptUI: null };
    }
  }

  if (Object.keys(geoOverrides).length) properties.geoOverride = geoOverrides;

  // tagVersion allows CMP to known if publishers installed the last CMP version in their sites
  properties.clientConfig = { ...config, tagVersion: 'V3' };

  return JSON.stringify(properties, null, 8);
};

/* it returns a string with the geo groups */
export const buildAmpGeo = (coreConfig) => {
  const { privacyMode = [], uspJurisdiction, displayUi } = coreConfig;
  const geoGroups = {};
  let ampGeoString = '';

  if (privacyMode.includes('USP')) {
    if (uspJurisdiction.includes('CA')) {
      geoGroups.usca = ['preset-us-ca'];
    } else if (uspJurisdiction.includes('US')) {
      geoGroups.us = ['us'];
    }
  }

  if (privacyMode.includes('GDPR') && displayUi === 'inEU') {
    geoGroups.eea = ['preset-eea', 'unknown'];
  }

  ampGeoString = Object.keys(geoGroups).length ?
    `<amp-geo layout="nodisplay">
      <script type="application/json">
      ${JSON.stringify({ ISOCountryGroups: geoGroups }, null, 8)}
      </script>
    </amp-geo>` : '';

  return ampGeoString;
};


/* it returns a string with the styles based on the configuration */
export const ampStylesTag = (coreConfig) => {
  const hasUsp = coreConfig ? coreConfig.privacyMode ? coreConfig.privacyMode.includes('USP'): [] : false;
  const hasGdpr = coreConfig ? coreConfig.privacyMode? coreConfig.privacyMode.includes('GDPR'): [] : false;
  const uspGeoGroup = coreConfig ? getUspGeoGroup(coreConfig.uspJurisdiction) : '';
  const styles = [];
  const gdprStyle =
  `#postPromptUI button {
      background: #368bd6;
      color: white;
      padding: 5px 15px;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      position: fixed;
      right: 0;
      bottom: 0;
      border-radius: 3px 0 0 3px;
      max-height: 30px;
      max-width: 110px;
      cursor: pointer;
    }`;
  const uspStyles = `.inmobi-disclaimer {
      display: none;
    }
      .amp-geo-group-${uspGeoGroup} .inmobi-disclaimer {
      display: block;
    }`;

  if (hasGdpr) styles.push(gdprStyle);
  if (hasUsp) styles.push(uspStyles);

  return `<style amp-custom>
  ${styles.join('\n')}
  </style>`;
};

/* it returns the amp tag */
export function tagCodeAmp(config, persistentLinkValue) {
  const { coreConfig } = config;
  const geoGroup = coreConfig ? buildAmpGeo(coreConfig) : '';
  const isUsp = coreConfig ? coreConfig.privacyMode ? coreConfig.privacyMode.includes('USP'): [] : false;
  const isGdpr = coreConfig ? coreConfig.privacyMode ? coreConfig.privacyMode.includes('GDPR'): [] : false;
  const customizedProperties = coreConfig ? buildProperties(config, persistentLinkValue) : '';
  const privacyButton = attribute => (
    `<div ${attribute}>
      <button role="button" on="tap:inmobi.prompt()">
        <svg style="height:20px">
            <g fill="none">
              <g fill="#FFF">
                <path
                  d="M16 10L15 9C15 9 15 8 15 8L16 7C16 7 16 6 16 6 16 5 15 4 14 3 14 2 13 2 13 3L12 3C12 3 11 3 11 2L11 1C11 1 10 0 10 0 9 0 7 0 6 0 6 0 5 1 5 1L5 2C5 3 4 3 4 3L3 3C3 2 2 2 2 3 1 4 0 5 0 6 0 6 0 7 0 7L1 8C1 8 1 9 1 9L0 10C0 10 0 11 0 11 0 12 1 13 2 14 2 15 3 15 3 14L4 14C4 14 5 14 5 15L5 16C5 16 6 17 6 17 7 17 9 17 10 17 10 17 11 16 11 16L11 15C11 14 12 14 12 14L13 14C13 15 14 15 14 14 15 13 16 12 16 11 16 11 16 10 16 10ZM13 13L12 13C11 13 11 13 9 14L9 16C9 16 7 16 7 16L7 14C5 14 5 13 4 13L3 13C2 13 1 12 1 11L3 10C2 9 2 8 3 7L1 6C1 5 2 4 3 4L4 4C5 4 5 3 7 3L7 1C7 1 9 1 9 1L9 3C11 3 11 4 12 4L13 4C14 4 15 5 15 6L13 7C14 8 14 9 13 10L15 11C15 12 14 13 13 13ZM8 5C6 5 5 7 5 9 5 10 6 12 8 12 10 12 11 10 11 9 11 7 10 5 8 5ZM8 11C7 11 6 10 6 9 6 7 7 6 8 6 9 6 10 7 10 9 10 10 9 11 8 11Z" />
              </g>
            </g>
        </svg>
        PRIVACY
      </button>
    </div>`
  );

  return `<!-- INMOBI CMP -->
    ${geoGroup}
    <amp-consent id="inmobi" layout="nodisplay">
      <script type="application/json">
        ${customizedProperties}
      </script>
      ${(persistentLinkValue && isGdpr) ? privacyButton('id="postPromptUI"') : ''}
      </amp-consent>
      ${(persistentLinkValue && isUsp) ? privacyButton('class="inmobi-disclaimer"') : ''}
<!--END INMOBI CMP -->
  `;
}

// @flow
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/fontawesome-pro-solid';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Styles from '../themesForm.module.scss';

type Props = {
  handleCustomLinks: Function;
  validations: Function;
  addCustomLink: boolean;
}

const CustomizeInitScreen = ({
  handleCustomLinks,
  validations,
  addCustomLink
}: Props) => (
  <Col span="24" className={Styles.customLinks}>
    <h5>
      CUSTOM FOOTER LINKS &nbsp;
      <span>The first entry in the custom footer list is reserved for your privacy policy.</span>
    </h5>
    <Row gutter={[16, 16]}>
      <Col span="12">
        <Form.Item
          name="uxInitialScreenCustomLinks0text"
          dependencies={['uxInitialScreenCustomLinks0url']}
          rules={validations.customLinksText('uxInitialScreenCustomLinks0url')}
        >
          <Input
            placeholder="Link text"
          />
        </Form.Item>
      </Col>
      <Col span="12">
        <Form.Item
          name="uxInitialScreenCustomLinks0url"
          dependencies={['uxInitialScreenCustomLinks0text']}
          rules={validations.customLinksUrl('uxInitialScreenCustomLinks0text')}
        >
          <Input
            data-testid="uxInitialScreenCustomLinks0url"
            placeholder="Link URL"
          />
        </Form.Item>
      </Col>
    </Row>
    {addCustomLink && (
      <Row gutter={[16, 16]}>
        <Col span="12">
          <Form.Item
            name="uxInitialScreenCustomLinks1text"
            rules={validations.customLinksText()}
          >
            <Input
              placeholder="Link text"
            />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item
            name="uxInitialScreenCustomLinks1url"
            rules={validations.customLinksUrl()}
          >
            <Input
              placeholder="Link URL"
            />
          </Form.Item>
        </Col>
      </Row>
    )}
    <button
      type="button"
      className={Styles.btnPlusToggle}
      onClick={() => handleCustomLinks()}
    >
      <span>
        {addCustomLink
          ? <FontAwesomeIcon icon={faMinus} size="xs" />
          : <FontAwesomeIcon icon={faPlus} size="xs" />}
      </span>
      <span>{addCustomLink ? 'Remove one' : 'Add one'}</span>
    </button>
  </Col>
);

export default CustomizeInitScreen;

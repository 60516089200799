import { Col, Row } from 'antd/es/grid';

import Card from 'antd/es/card';
import Checkbox from 'antd/es/checkbox';
import { FIELD_REQUIRED_TEXT } from '../../../../utils/constants';
import Form from 'antd/es/form';
import InfoIconTooltip from '../../../ui/infoIconTooltip';
import Input from 'antd/es/input';
// @flow
import React from 'react';
import SITETOOLTIPS from '../../../../utils/toolTipsMessages';
import Space from 'antd/es/space';
import Styles from '../themesForm.module.scss';
import Tooltip from 'antd/es/tooltip';

type Props = {
  validations: Object;
  changeRegulations: Function;
}

function ConfigureTheme({ validations, changeRegulations }: Props) {
  const regulationLabels = {
    GDPR: 'European regulation (GDPR)',
    USP: 'US regulation'
  };

  return (
    <Card title="Configure your theme">
      <Col span="24">
        <Row>
          <Col span="24">
            <Form.Item
              label="THEME NAME"
              name="themeName"
              required
              rules={validations.themeName}
            >
              <Input
                placeholder="Name your theme"
                required
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <Form.Item
              label="REGULATION"
              name="privacyModes"
              className={Styles.regulationCheckboxes}
              rules={validations.regulation}
            >
              <Checkbox.Group onChange={changeRegulations}>
                <Space direction="vertical">
                  <Checkbox value="GDPR">
                    {regulationLabels.GDPR}
                  </Checkbox>
                  <div>
                    <Checkbox value="USP">
                      {regulationLabels.USP}
                    </Checkbox>
                    <Tooltip
                      title={SITETOOLTIPS.CCPA}
                      placement="right"
                    >
                      <span className="info-icon-container"><InfoIconTooltip /></span>
                    </Tooltip>
                  </div>
                </Space>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Card>
  );
}

export default ConfigureTheme;

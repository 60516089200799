import { Button, Checkbox, Col, Divider, Form, Modal, Radio, Row, Select, Space, Tooltip } from 'antd/es';
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import uid from 'uid';
import { FIELD_REQUIRED_TEXT, GBC_CMP_VERSION, GBC_LOCATIONS } from '../../../utils/constants';

import Alert from '../../ui/alert';
import InfoIconTooltip from '../../ui/infoIconTooltip';
import SITETOOLTIPS from '../../../utils/toolTipsMessages';
import Styles from './gbcForm.module.scss';
import TEXT from './text';
import { GBC_FIELDS, defaultValueOptions, gbcEnabledOptions } from './constants';
import { isFieldEnabled } from '../../../utils/sites';

const { Option } = Select;

// @flow
type TPurpose = {
  id: string,
  portal: {
    title: string
  }
}

type Props = {
  setFieldsValue: Function,
  getFieldValue: Function,
  handleChange: Function,
  validateFields: Function,
  values: Record<string, any>,
  purposesData: Array<TPurpose>,
  propertyType: string,
};

const GBCForm = ({
  setFieldsValue, getFieldValue, handleChange, validateFields, values,
  purposesData, propertyType = 'site'
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { ENABLED, LOCATIONS, APPLICABLE_PURPOSES, URL_PASS_THROUGH, ADS_DATA_REDACTION } = GBC_FIELDS;
  const getPurposeIds = useCallback(() => {
    if (values.gbcApplicablePurposes) {
      return values.gbcApplicablePurposes.filter(purpose => purpose.enabled).map(({ id }) => id);
    }
    return [];
  }, [values.gbcApplicablePurposes]);
  const [purposeIds, setPurposeIds] = useState(getPurposeIds());
  const [formError, setFormError] = useState(false);

  const isEditEnabled = values.gbcEnabled;

  const onCancel = () => {
    const atleastOnePurposeSaved = values.gbcApplicablePurposes
      ? values.gbcApplicablePurposes.some(purpose => purpose.enabled)
      : false;
    if (!atleastOnePurposeSaved) {
      setFieldsValue({ gbcEnabled: false });
      handleChange(ENABLED, false);
      validateFields(['privacyModes']);
    } else {
      setFieldsValue({ gbcApplicablePurposes: values.gbcApplicablePurposes });
      setFieldsValue({ gbcLocations: values.gbcLocations });

      const ids = values.gbcApplicablePurposes.filter(purpose => purpose.enabled).map(({ id }) => id);
      setPurposeIds(ids);
    }

    setFieldsValue({ urlPassThrough: values.urlPassThrough });
    setFieldsValue({ adsDataRedaction: values.adsDataRedaction });

    setModalOpen(false);
  };

  const handleGbcEnableChange = (e) => {
    if (e.target.value) {
      setModalOpen(true);
    }
    handleChange(ENABLED, e.target.value);
    validateFields(['privacyModes']);
  };

  const purposeOptions = purposesData.map(purpose => ({
    label: purpose.portal.title,
    value: purpose.id,
    key: purpose.id
  }));

  const onSave = () => {
    validateFields([LOCATIONS, APPLICABLE_PURPOSES, URL_PASS_THROUGH, ADS_DATA_REDACTION])
      .then((fields: Object) => {
        const { gbcApplicablePurposes } = fields;
        const atleastOnePurposeSaved = gbcApplicablePurposes.some(purpose => purpose.enabled);
        if (!atleastOnePurposeSaved) {
          setFormError(true);
          return;
        }

        handleChange([LOCATIONS, APPLICABLE_PURPOSES, URL_PASS_THROUGH, ADS_DATA_REDACTION], fields);
        setModalOpen(false);
      })
      .catch((errorInfo) => {
        console.log('errorInfo', errorInfo);
      });
  };

  const handlePurposeIdChange = (e, id) => {
    setFormError(false);
    if (e.target.checked) {
      setPurposeIds([...purposeIds, id]);
    } else {
      setPurposeIds(purposeIds.filter(purposeId => purposeId !== id));
    }
  };

  return (
    <div className={Styles.gbcForm}>
      <Row justify="space-between">
        <Col>
          <Form.Item
            label={(
              <Space direction="vertical" size={6}>
                <Space>
                  <span>{propertyType === 'site' ? TEXT.GBC_ENABLED_LABEL : TEXT.GBC_ENABLED_LABEL_APP}</span>
                  <Tooltip title={SITETOOLTIPS.enableGBC} placement="top">
                    <span>
                      <InfoIconTooltip />
                    </span>
                  </Tooltip>
                </Space>
                <span className={Styles.subTitle}>{TEXT.GBC_ENABLED_SUB_LABEL}</span>
              </Space>
            )}
            name="gbcEnabled"
            className={Styles.formItem}
          >
            <Radio.Group data-testid="gbc-enabled" onChange={handleGbcEnableChange} options={gbcEnabledOptions} />
          </Form.Item>
        </Col>

        {isEditEnabled && (
          <Col>
            <Button
              type="link"
              onClick={() => setModalOpen(true)}
              className={Styles.EditButton}
              data-testid="edit"
            >
              {TEXT.EDIT}
            </Button>
          </Col>
        )}
      </Row>

      <Modal
        title={TEXT.MODAL_TITLE}
        onClose={onCancel}
        onCancel={onCancel}
        open={modalOpen}
        closable={false}
        onOk={onSave}
        className={Styles.gbcForm}
        width={550}
        data-testid="gbc-modal"
      >
        <Form.Item
          label={TEXT.LOCATION_LABEL}
          colon={false}
          name="gbcLocations"
          className={Styles.formItem}
          tooltip={{
            icon: (
              <span>
                <InfoIconTooltip />
              </span>
            ),
            title: SITETOOLTIPS.gbcLocation
          }}
          required
          rules={[{
            required: true,
            message: FIELD_REQUIRED_TEXT
          }]}
        >
          <Select>
            {GBC_LOCATIONS.map(opt => (
              <Option key={opt.value} value={opt.value}>
                {opt.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Divider />

        <Col>
          <Row>
            {formError && (
              <Alert
                type="error"
                message={TEXT.ALERT}
                closable
                afterClose={() => setFormError(false)}
                className={Styles.alert}
              />
            )}
          </Row>
          <Row>
            {purposeOptions.map((purpose, index) => (
              <>
                <Form.Item
                  name={[APPLICABLE_PURPOSES, index, 'enabled']}
                  valuePropName="checked"
                  className={Styles.formItem}
                  key={uid()}
                >
                  <Checkbox onChange={e => handlePurposeIdChange(e, purpose.key)} key={purpose.value}>
                    {purpose.label}
                  </Checkbox>
                </Form.Item>
                {purposeIds.indexOf(purpose.key) !== -1 && (
                  <Form.Item
                    colon={false}
                    name={[APPLICABLE_PURPOSES, index, 'defaultValue']}
                    label={TEXT.DEFAULT_VALUE_LABEL}
                    tooltip={{
                      icon: (
                        <span>
                          <InfoIconTooltip />
                        </span>
                      ),
                      title: SITETOOLTIPS.gbcDefaultValue
                    }}
                    className={classNames(Styles.formItem, Styles.radioItem)}
                    key={uid()}
                  >
                    <Radio.Group options={defaultValueOptions} />
                  </Form.Item>
                )}
              </>
            ))}
          </Row>
        </Col>

        { propertyType === 'site'
          && isFieldEnabled('urlPassThrough', values.cmpVersion)
          && isFieldEnabled('adsDataRedaction', values.cmpVersion) && (
          <>
            <Divider />
            <Space direction="vertical" size={10}>
              <p className={Styles.title}>{TEXT.ADDITIONAL_SETTINGS}</p>
              <Space direction="vertical" size={0}>
                <Space size={0}>
                  <Form.Item
                    name="urlPassThrough"
                    className={Styles.formItem}
                    valuePropName="checked"
                  >
                    <Checkbox>
                      {TEXT.URL_PASS_THROUGH}
                    </Checkbox>
                  </Form.Item>
                  <Tooltip title={SITETOOLTIPS.UrlPassThrough} placement="right">
                    <span>
                      <InfoIconTooltip />
                    </span>
                  </Tooltip>
                </Space>
                <Space size={0}>
                  <Form.Item
                    name="adsDataRedaction"
                    className={Styles.formItem}
                    valuePropName="checked"
                  >
                    <Checkbox>
                      {TEXT.ADS_DATA_REDACTION}
                    </Checkbox>
                  </Form.Item>
                  <Tooltip title={SITETOOLTIPS.AdsDataRedaction} placement="right">
                    <span>
                      <InfoIconTooltip />
                    </span>
                  </Tooltip>
                </Space>

              </Space>
            </Space>
          </>
        )}
      </Modal>
    </div>
  );
};

export default GBCForm;

import { Col, Row } from 'antd/es/grid';
import { GENERAL_FORM_ERROR, GVL_VERSION_3, XSS_ERROR_TEXT } from '../../../utils/constants';
// @flow
import React, { useCallback, useEffect, useState } from 'react';

import Alert from '../../ui/alert';
import Button from 'antd/es/button';
import ConfigureTheme from './components/configure-theme';
import ContentHeader from '../../ui/content-header';
import CustomizeCCPA from '../../../pages/apps/components/customize-ccpa';
import CustomizePersistentConsent from './components/customize-consent';
import CustomizeUI from './components/customize-ui';
import Form from 'antd/es/form';
import Preview from '../../ui/preview';
import PurposesV2 from './components/purposes-v2';
import SITETOOLTIPS from '../../../utils/toolTipsMessages';
import Space from 'antd/es/space';
import Styles from './themesForm.module.scss';
import { autoDetectSupportedLanguage } from '../../../utils/language';
import { formatCustomLinks } from '../../../utils/themes';
import validate from './syncValidation';
import { validateUserInput } from '../../../utils/sites';

type Props = {
  mode: string,
  stacks: Object;
  themes: Object;
  app: Object;
  location: Object;
  createTheme: Function;
  editTheme: Function;
  idParams: string;
  gvlData: Object;
  updateTCFVersion: Function;
};


const ThemesForm = ({
  mode,
  stacks,
  themes,
  app,
  location,
  createTheme,
  editTheme,
  idParams,
  gvlData,
  updateTCFVersion,
}: Props) => {
  const [form] = Form.useForm();
  const [language, setLanguage] = useState(autoDetectSupportedLanguage());
  const [hasChangedValues, setHasChangedValues] = useState(false);
  const [addCustomLink, setAddCustomLink] = useState(false);
  const { resetFields, validateFields, setFieldsValue, getFieldsValue } = form;
  const { accountId, pCode, isPremium } = app;
  const { current } = themes;
  const [showPreview, setShowPreview] = useState(false);
  const [generalFormError, setGeneralError] = useState(false);
  const [generalErrorMessage, setGeneralErrorMessage] = useState(GENERAL_FORM_ERROR);
  const [values, setValues] = useState({});
  const validations = validate();

  const onValuesChange = useCallback(() => {
    if (!hasChangedValues) {
      setHasChangedValues(true);
    }
  }, [hasChangedValues]);

  useEffect(() => {
    const { formError } = themes;
    if (formError && formError.message) {
      setGeneralError(true);
      /* Removed toLowerCase() so the first letter of the error messages is in Upercase, need to review error messages
      after US https://jira.quantcast.com/browse/GDPRC-4661 is finished */
      setGeneralErrorMessage(formError.message);
    }
  }, [themes.formError]);

  useEffect(() => {
    if (!hasChangedValues && values.uspDnsText && (current.uspDnsText.length !== values.uspDnsText.length)) {
      setHasChangedValues(true);
    }
  }, [values.uspDnsText]);

  const getScreenCustomLinks = (valuesToSend) => {
    const uxInitialScreemLinks = [];
    if (valuesToSend.uxInitialScreenCustomLinks0text) {
      uxInitialScreemLinks.push({
        text: valuesToSend.uxInitialScreenCustomLinks0text,
        url: valuesToSend.uxInitialScreenCustomLinks0url
      });
    }
    if (valuesToSend.uxInitialScreenCustomLinks1text) {
      uxInitialScreemLinks.push({
        text: valuesToSend.uxInitialScreenCustomLinks1text,
        url: valuesToSend.uxInitialScreenCustomLinks1url
      });
    }
    return uxInitialScreemLinks;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    form.validateFields().then((formValues) => {
      const params = new URLSearchParams(location.search);
      const valuesToSend = {
        ...values,
        ...formValues,
        uxInitialScreenCustomLinks0text: formValues.uxInitialScreenCustomLinks0text,
        uxInitialScreenCustomLinks0url: formValues.uxInitialScreenCustomLinks0url,
        uxInitialScreenCustomLinks1text: formValues.uxInitialScreenCustomLinks1text,
        uxInitialScreenCustomLinks1url: formValues.uxInitialScreenCustomLinks1url,
      };
      const uxInitialScreemLinks = [];
      if (valuesToSend.uxInitialScreenCustomLinks0text) {
        uxInitialScreemLinks.push({
          text: valuesToSend.uxInitialScreenCustomLinks0text,
          url: valuesToSend.uxInitialScreenCustomLinks0url
        });
        delete valuesToSend.uxInitialScreenCustomLinks0text;
        delete valuesToSend.uxInitialScreenCustomLinks0url;
      }
      if (valuesToSend.uxInitialScreenCustomLinks1text) {
        uxInitialScreemLinks.push({
          text: valuesToSend.uxInitialScreenCustomLinks1text,
          url: valuesToSend.uxInitialScreenCustomLinks1url
        });
        delete valuesToSend.uxInitialScreenCustomLinks1text;
        delete valuesToSend.uxInitialScreenCustomLinks1url;
      }
      valuesToSend.uxInitialScreenCustomLinks = uxInitialScreemLinks;
      Object.keys(valuesToSend).forEach((key) => {
        if (valuesToSend[key] === null) {
          delete valuesToSend[key];
        }
      });

      if (stacks.list.length && valuesToSend.use === 'stacks') {
        if (!valuesToSend.userSelectedStack) {
          valuesToSend.stacks = stacks.list[0].stacks;
        }
      }

      if (
        'publisherConsentRestrictionIds' in valuesToSend && !valuesToSend.publisherConsentRestrictionIds.length
      ) {
        delete valuesToSend.publisherConsentRestrictionIds;
      }

      if (('publisherLIRestrictionIds' in valuesToSend)
        && !(valuesToSend.publisherLIRestrictionIds.length)) {
        delete valuesToSend.publisherLIRestrictionIds;
      }

      // Validate html input for XSS
      const areValidInput = validateUserInput({ uspDnsText: valuesToSend.uspDnsText });
      if (!areValidInput) {
        throw new Error('XSS_ERROR');
      }

      if (mode === 'Create') {
        delete valuesToSend.v2HasCCPA;
        delete valuesToSend.v2HasGDPR;
        delete valuesToSend.userSelectedStack;
        delete valuesToSend.use;
        createTheme({ ...valuesToSend }, params.get('from-site'));
      } else {
        valuesToSend.privacyModes = [];

        if (valuesToSend.v2HasGDPR) {
          valuesToSend.privacyModes.push('GDPR');
        }

        if (valuesToSend.v2HasCCPA) {
          valuesToSend.privacyModes.push('USP');
        }

        if (valuesToSend.use === 'purposes') {
          valuesToSend.stacks = [];
        }

        if ('vendorFlexiblePurposesIds' in valuesToSend) {
          valuesToSend.vendorFlexiblePurposesIds.forEach((item) => {
            delete valuesToSend[`CONSENT_${item}`];
            delete valuesToSend[`LI_${item}`];
          });
        }

        delete valuesToSend.vendorFlexiblePurposesIds;
        delete valuesToSend.v2HasCCPA;
        delete valuesToSend.v2HasGDPR;
        delete valuesToSend.userSelectedStack;
        delete valuesToSend.use;
        editTheme(idParams, valuesToSend);
      }
    }).catch((err) => {
      setGeneralError(true);
      if(err.message === 'XSS_ERROR') {
        setGeneralErrorMessage(XSS_ERROR_TEXT);
      } else {
        setGeneralErrorMessage(GENERAL_FORM_ERROR);
      }
    });
  };

  const formatInitData = () => {
    let persistentConsentButton = true;
    let initScreenButton = true;
    let uxInitialScreenCustomLinks;
    let uxInitialScreenCustomLinks0text;
    let uxInitialScreenCustomLinks0url;
    let uxInitialScreenCustomLinks1text;
    let uxInitialScreenCustomLinks1url;
    let uxShowRejectButton = 'true';
    const privacyModes = [];
    let v2HasCCPA = false;
    let v2HasGDPR = false;
    let use;

    if (current.privacyModes) {
      if (current.privacyModes.includes('GDPR')) {
        privacyModes.push('GDPR');
        v2HasGDPR = true;
      }
      if (current.privacyModes.includes('USP')) {
        privacyModes.push('USP');
        v2HasCCPA = true;
      }
    } else {
      privacyModes.push('GDPR');
      v2HasGDPR = true;
      privacyModes.push('USP');
      v2HasCCPA = true;
    }

    if (current.uxInitialScreenCustomLinks) {
      uxInitialScreenCustomLinks = formatCustomLinks(current.uxInitialScreenCustomLinks);
      if (uxInitialScreenCustomLinks[0]) {
        uxInitialScreenCustomLinks0text = uxInitialScreenCustomLinks[0].text;
        uxInitialScreenCustomLinks0url = uxInitialScreenCustomLinks[0].url;
      }
      if (uxInitialScreenCustomLinks[1]) {
        uxInitialScreenCustomLinks1text = uxInitialScreenCustomLinks[1].text;
        uxInitialScreenCustomLinks1url = uxInitialScreenCustomLinks[1].url;
      }
    }

    if ('uxShowRejectButton' in current) {
      uxShowRejectButton = current.uxShowRejectButton.toString();
    }

    if (typeof current.uxPersistentConsentButton === 'boolean') {
      persistentConsentButton = current.uxPersistentConsentButton;
    }
    if (typeof current.initScreenRejectButtonShowing === 'boolean') {
      initScreenButton = current.initScreenRejectButtonShowing;
    }

    if (current.stacks) {
      use = current.stacks.length ? 'stacks' : 'purposes';
    } else {
      use = 'stacks';
    }

    return {
      accountId,
      pCode,
      themeName: current.themeName || '',
      language: current.language || autoDetectSupportedLanguage(),
      privacyModes,
      v2HasGDPR,
      v2HasCCPA,
      displayUi: current.displayUi || 'popup',
      uxSoftOptIns: current.uxSoftOptIns || false,
      uxShowRejectButton,
      uxCustomizedCss: current.uxCustomizedCss || null,
      uxFontColor: current.uxFontColor || null,
      uxLinkColor: current.uxLinkColor || null,
      uxBackgroundColor: current.uxBackgroundColor || null,
      uxToogleActiveColor: current.uxToogleActiveColor || null,
      uxPrimaryButtonColor: current.uxPrimaryButtonColor || null,
      uxPrimaryButtonTextColor: current.uxPrimaryButtonTextColor || null,
      uxSecondaryButtonColor: current.uxSecondaryButtonColor || null,
      uxSecondaryButtonTextColor: current.uxSecondaryButtonTextColor || null,
      initScreenTitleText: current.initScreenTitleText,
      initScreenBodyText: current.initScreenBodyText || '1',
      saveAndExitButton: current.saveAndExitButton || null,
      agreeButton: current.agreeButton || '',
      initScreenRejectButton: current.initScreenRejectButton || null,
      initScreenAcceptButtonText: current.initScreenAcceptButtonText || null,
      initScreenRejectButtonText: current.initScreenRejectButtonText || null,
      initScreenPurposeLinkText: current.initScreenPurposeLinkText || null,
      uxInitialScreenCustomLinks,
      uxInitialScreenCustomLinks0text,
      uxInitialScreenCustomLinks0url,
      uxInitialScreenCustomLinks1text,
      uxInitialScreenCustomLinks1url,
      uxDefaultValueForToggles: current.uxDefaultValueForToggles || 'off',
      purposeScreenTitleText: current.purposeScreenTitleText || null,
      purposeScreenBodyText: current.purposeScreenBodyText || null,
      acceptAllButtonText: current.acceptAllButtonText || null,
      purposeScreenVendorLinkText: current.purposeScreenVendorLinkText || null,
      purposeScreenSaveAndExitButtonText: current.purposeScreenSaveAndExitButtonText || null,
      purposeScreenCancelButtonText: current.purposeScreenCancelButtonText || null,
      vendorScreenSaveAndExitButtonText: current.vendorScreenSaveAndExitButtonText || null,
      uxPersistentConsentButton: persistentConsentButton,
      consentLinkLabel: current.consentLinkLabel || '',
      uspDnsTitleText: current.uspDnsTitleText || '',
      uspDnsText: current.uspDnsText || '',
      uspDeleteDataLinkText: current.uspDeleteDataLinkText || null,
      uspDeleteDataLink: current.uspDeleteDataLink || null,
      uspAccessDataLinkText: current.uspAccessDataLinkText || null,
      uspAccessDataLink: current.uspAccessDataLink || null,
      uspPrivacyPolicyLinkText: current.uspPrivacyPolicyLinkText || null,
      uspPrivacyPolicyLink: current.uspPrivacyPolicyLink || null,
      initialScreen: current.initialScreen || 'SUMMARY',
      initScreenRejectButtonShowing: initScreenButton,
      initScreenCloseButtonShowing: current.initScreenCloseButtonShowing || false,
      suppressCcpaLinks: current.suppressCcpaLinks || false,
      uxPersistentConsentLinkLocation: current.uxPersistentConsentLinkLocation || 'BOTTOM_RIGHT',
      vendorPurposeIds: values.vendorPurposeIds || current.vendorPurposeIds || [],
      vendorPurposeLegitimateInterestIds: current.vendorPurposeLegitimateInterestIds || [],
      vendorSpecialFeaturesIds: current.vendorSpecialFeaturesIds || [],
      stacks: current.stacks || [],
      vendorSpecialPurposesIds: current.vendorSpecialPurposesIds || [],
      vendorFeaturesIds: current.vendorFeaturesIds || [],
      publisherConsentRestrictionIds: current.publisherConsentRestrictionIds || [],
      publisherLIRestrictionIds: current.publisherLIRestrictionIds || [],
      use,
      gvlVersion: current.gvlVersion || GVL_VERSION_3,
      userSelectedStack: current.stacks && current.stacks.length > 0,
    };
  };

  const filterPurposesTCFVersion = () => {
    const keysToMap = [
      {
        gvlKey: 'purposes',
        vendorKey: 'vendorPurposeIds'
      },
      {
        gvlKey: 'legIntPurposes',
        vendorKey: 'vendorPurposeLegitimateInterestIds'
      },
      {
        gvlKey: 'features',
        vendorKey: 'vendorFeaturesIds'
      },
      {
        gvlKey: 'specialFeatures',
        vendorKey: 'vendorSpecialFeaturesIds'
      },
      {
        gvlKey: 'specialPurposes',
        vendorKey: 'vendorSpecialPurposesIds'
      },
      {
        gvlKey: 'flexiblePurposes',
        vendorKey: 'vendorFlexiblePurposesIds'
      }
    ];

    return gvlData.vendors.filter(vendor => !gvlData.blockedVendors.includes(vendor.id))
      .reduce((acc, vendor) => {
        keysToMap.forEach((key) => {
          if (vendor[key.gvlKey]) {
            vendor[key.gvlKey].forEach((item) => {
              if (!acc[key.vendorKey].includes(item)) {
                acc[key.vendorKey].push(item);
              }
            });
          }
        });
        return acc;
      }, {
        vendorPurposeIds: [],
        vendorPurposeLegitimateInterestIds: [],
        vendorFeaturesIds: [],
        vendorSpecialFeaturesIds: [],
        vendorSpecialPurposesIds: [],
        vendorFlexiblePurposesIds: []
      });
  };

  useEffect(() => {
    const filterPurposes = filterPurposesTCFVersion();
    setValues({ ...values, ...filterPurposes, gvlVersion: gvlData.gvlSpecificationVersion });
  }, [gvlData.gvlSpecificationVersion]);

  useEffect(() => {
    const filteredData = filterPurposesTCFVersion();
    const formValues = formatInitData();

    if (formValues.uxInitialScreenCustomLinks1text) {
      setAddCustomLink(true);
    }
    if (current && Object.keys(current).length) {
      if (formValues.uxInitialScreenCustomLinks1text) {
        setAddCustomLink(true);
      }
      if (current.uxInitialScreenCustomLinks && Object.keys(current.uxInitialScreenCustomLinks).length === 2) {
        setAddCustomLink(true);
      }
      resetFields();
    }
    setValues({ ...formValues, ...filteredData });
  }, []);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  const handleDisagreeButtonChange = (input) => {
    const { checked, name } = input.target;
    setValues(preValues => ({ ...preValues, [name]: checked }));
  };

  const changeRegulations = (regulations) => {
    setValues(preValues => ({
      ...preValues,
      v2HasGDPR: regulations.includes('GDPR'),
      v2HasCCPA: regulations.includes('USP')
    }));
  };

  const normalizeHex = hex => (hex ? `#${hex.split('#').join('')}` : '');

  const togglePreview = () => {
    setShowPreview(prevState => (!prevState));
  };

  const handleCustomLinks = () => {
    setAddCustomLink((prevState) => {
      if (prevState) {
        setFieldsValue({ uxInitialScreenCustomLinks1text: undefined });
        setFieldsValue({ uxInitialScreenCustomLinks1url: undefined });
        setValues(preValues => ({
          ...preValues,
          uxInitialScreenCustomLinks1text: undefined,
          uxInitialScreenCustomLinks1url: undefined
        }));
      }
      return !prevState;
    });
  };

  const actionButtons = () => {
    const buttons = [
      {
        component: Button,
        props: {
          type: 'primary',
          'data-test': 'themes_form_show_preview',
          onClick: togglePreview,
        },
        children: ['PREVIEW GDPR MODE'],
      },
      {
        component: Button,
        props: {
          type: 'primary',
          'data-test': '',
          disabled: !hasChangedValues || !(form && !('syncErrors' in form)),
          onClick: handleSubmit,
        },
        children: ['SAVE THEME'],
      }
    ];
    return [{
      component: Space,
      children: buttons
    }];
  };

  const renderHeader = () => (
    <ContentHeader
      title={`${mode} a theme`}
      toolTipTitle={SITETOOLTIPS.createEditTheme(location && location.pathname)}
      backBtn={{ goTo: `p-${pCode}/themes`, text: 'Back to themes' }}
      actions={actionButtons()}
    />
  );

  const getPreviewValues = () => {
    const valuesToShow = {
      ...values,
      ...getFieldsValue()
    };
    valuesToShow.uxInitialScreenCustomLinks = getScreenCustomLinks(valuesToShow);
    return valuesToShow;
  };

  const onChangeTCFVersion = (gvlVersion) => {
    updateTCFVersion(gvlVersion);
  };

  return (
    <>
      {showPreview && (
        <Preview
          closeModal={togglePreview}
          formValues={getPreviewValues()}
        />
      )}
      <div className={Styles.header}>
        {renderHeader()}
      </div>
      <div className={Styles.container}>
        <section className={Styles.content}>
          <Row gutter={[16, 16]}>
            <Col span="24">
              {generalFormError && (
                <Alert
                  type="error"
                  message={generalErrorMessage}
                  closable
                />
              )}
            </Col>
            <Col span="24">
              <Form
                name="themes-control-ref"
                form={form}
                className={Styles.cardsWrapper}
                layout="vertical"
                initialValues={{ ...formatInitData() }}
                onValuesChange={onValuesChange}
              >
                <ConfigureTheme
                  isPremium={isPremium}
                  mode={mode}
                  validations={validations}
                  changeRegulations={changeRegulations}
                />
                {values && values.v2HasCCPA
                  && (
                    <CustomizeCCPA
                      values={values}
                      handleChange={setValues}
                      validations={validations}
                      setFields={setFieldsValue}
                    />
                  )
                }
                {values.v2HasGDPR && (
                  <>
                    <CustomizeUI
                      normalizeHex={normalizeHex}
                      handleLanguageChange={handleLanguageChange}
                      handleDisagreeButtonChange={handleDisagreeButtonChange}
                      handleCustomLinks={handleCustomLinks}
                      addCustomLink={addCustomLink}
                      language={language}
                      values={values}
                      validations={validations}
                      validateFields={validateFields}
                      onChange={setValues}
                    />
                    <PurposesV2
                      purposeIds={values.vendorPurposeIds}
                      purposeLegitimateIds={values.vendorPurposeLegitimateInterestIds}
                      specialFeatures={values.vendorSpecialFeaturesIds}
                      stackIds={values.stacks}
                      specialPurposes={values.vendorSpecialPurposesIds}
                      features={values.vendorFeaturesIds}
                      flexiblePurposes={values.vendorFlexiblePurposesIds || []}
                      publisherConsentRestrictionIds={values.publisherConsentRestrictionIds}
                      publisherLIRestrictionIds={values.publisherLIRestrictionIds}
                      use={values.use}
                      gvlVersion={values.gvlVersion}
                      onChange={(newValues) => {
                        setValues(preValues => ({ ...preValues, ...newValues }));
                        onValuesChange();
                      }}
                      vendorsData={gvlData}
                      stacks={values.stacks}
                      userSelectedStack={values.userSelectedStack}
                      initData={values}
                      onChangeTCFVersion={onChangeTCFVersion}
                    />
                    <CustomizePersistentConsent
                      language={language}
                      isSoftOptIn={values.uxSoftOptIns}
                      validations={validations}
                    />
                  </>
                )}
              </Form>
            </Col>
          </Row>
        </section>
        <aside className={Styles.aside} />
      </div>
    </>
  );
};


export default ThemesForm;
